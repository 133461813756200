import React from "react";

const ModalOneBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Ashraf Edlous
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 9 January 2021
        </span>
        <span>
          <i className="fa fa-tags"></i> محرك البحث الصور
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>رابع اهم مصدر تراقيك في الويب  | صور جوجل</h1>
      <img src="img/blog/blog-post-1.jpg" className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        أكثر من 27% من عمليات البحث على الويب تتم على صور جوجل
        </p>
        <div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>
          ـ 11 من أهم عوامل تحسين إمكانية ظهور صورك في نتائج البحث على جوجل بدون التأثير على سرعة تحميل صفحات الموقع
          </p>
        </div>
        <p>
        ـ  1 _ التنسيق الصحيح … لازم تعرف كل نوع من الصور ايش ميزاته:**
        </p>
        <p>
         PNG جودة عالية وحجم كبير
        </p>
        <p>
         JPEG الجودة أقل والحجم كذلك
        </p>
        <p>
        ضغط الحجم أقل قدر ممكن مع المحافظة على جودة الصورة بس مشكلتها تدعمها متصفح كروم وفايرفوكس  
          WebP 
        </p>
        <p>
        ـ  2  _ ضغط حجم الصور**
        </p>
        <p>
        تاخذ الصور 21٪ من إجمالي حجم صفحتك وسطيا ممكن تزيد او تنقص من موقع لموقع
مجرد تحسين حجم الصور وضغطها رح يسرع موقعك تقريبا 2 ثانية وهذي تفرق كثير في تجربة المستخدم وكذلك في أرشفة قوقل للصفحات
أدوات لضغط حجم الصور
في منصات كثير تساعدك بعملية ضغط الصور منها:
        </p>
        <p>
        callrail.com  OR squoosh.app
        </p>
        <p>
        واذا كنت تستخدم وورد بريس ممكن تجرب اضافة
حتى تعرف تأثير حجم الصور علي سرعة صفحات موقعك تقدر تستخدم هالأداة من جوجل  لفحص صفحات الموقع وسرعتها وأسباب بطئها

        </p>
        <p>
        ـ  3  _ استخدم صور خاصة فيك
        </p>
        <p>
لا تاخذ صور من مواقع تبيع أو تقدم صور مجانية حاول تعمل تصاميم و صور خاصة حتى تظهر بمحرك البحث
لا تكرر الصور اللي تظهر غالبا في المواقع اللي في مجالك
هذا يؤثر كمان في تجربة المستخدم وثقته بمحتواك وموقعك
حاول تكون الصور المستخدمة بأبعاد كبيرة عرضها 1200 بكسل
        </p>
        <p>
        ـ  5  _  أسماء ملفات الصور
        </p>
        <p>
        اسم الملف والكلمة المفتاحية اللي بتدل على محتوى الصورة لهم علاقة قوية لما نكون حابين تظهر الصورة بنتايج محرك البحث
ضروري يكون اسم الصورة يدل على محتواها وبالتالي تكون لها علاقة بالكلمة المفتاحية اللي تخدمها واللي تظهر في المحتوى اللي يدعم هالكلمة
        </p>
        <p>
        ـ  6 _  النص البديل
        </p>
        <p>
        في نص يظهر لما الصورة ما تفتح عشان يفهم اللي يتصفح ايش الصورة اللي كانت هنا
ضروري يكون في هذا النص وصف لمحتويات الصورة وتأكد تستخدم الكلمة المفتاحية لان محرك البحث رح يميز الصورة أكثر ويرفع تصنيفها للكلمات الملائمة لها لما يبحث عنها المستخدم

        </p>
        <p>
        ـ 7 _ رابط الصورة
        </p>
        <p>
        لا تحط كل الصور بملف واحد
حاول تحط الصور بمجلدات بأسماء لها علاقة بمحتوي الصورة حتى يقرأ محرك البحث الرابط ويصنفها على اساسه
        </p>
        <p>
        ـ 8  _ عنوان الصفحة ووصفها
        </p>
        <p>
        يؤثر عنوان الصفحة ووصف الصفحة على الصور اللي تحتويها الصفحة في الأرشفة في قوقل وبالتالي في ظهورها في نتايج الصور
        </p>
        <p>
        ـ 9 _ تحديد الأبعاد

        </p>
        <p>
        يفترض تحدد ابعاد الصورة لما تستخدمها في صفحة بالموقع تختلف عن الابعاد الاساسية للصورة لأن المتصفح ممكن يغير حجم الصورة وتطلع كبيرة ببعض الأجهزة وتاخذ مساحة كبيرة من الصفحة وتؤدي لتجربة سيئة للمستخدم
        </p>
        <p>
        ـ 10_ التوافق مع الجوال
        </p>
        <p>
        تقدر تخصص صور سريعة التحميل والاستجابة لحجم شاشة الجهاز وهذا الشي يتطلب شوية خبرة بأكواد Css
        </p>
        <p>
        ـSitemap اخر شي اضافة ملف 
        </p>
        <p>
        ممكن تضيف الصور لملف Sitemap أو تخصص لها ملف منفصل خاص بالصور
وهذا يؤثر بشكل كبير في أرشفة قوقل للصور اللي في موقعك
بهالشكل يفترض يكون عندك صور ممتازة تجيب لك زيارات كثيرة حاول تستخدمها كأداة جذب للزوار والعملاء وتستغل ظهورها بمحرك البحث
استمر بالتحسين والبحث في نتايج الصور وشوف المنافسين وكمان شوف المنافسين العالميين بلغات ثانية ايش يقدموا وقدم شي أفضل حتى تستقطب زوار وتحولهم لعملاء
        </p>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalOneBlogContent;
