import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Ashraf" },
  { meta: "last name", metaInfo: "Eldous" },
  { meta: "Age", metaInfo: "29 Years" },
  { meta: "Nationality", metaInfo: "Palestine" },
  { meta: "The Work", metaInfo: "Google" },
  { meta: "Address", metaInfo: "Gaza strip" },
  { meta: "phone", metaInfo: "+972598996527" },
  { meta: "Email", metaInfo: "Google@ashrafeldous.com" },
  { meta: "Skype", metaInfo: "live:a-f-l-l" },
  { meta: "langages", metaInfo: "Arabic, English" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
