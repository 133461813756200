import React from "react";

const experienceContent = [
  {
    year: "   2010 ",
    position: "الامن السيبراني",
    compnayName: "Cyber Security",
    details: `  اكتشاف ثغرة أمنية عالية الخطورة في عدة شركات من ضمنهم شركة الكويت للبترول ومن الجدير بالذكر أنه تم إغلاق الثغرة الأمنية. `,
  },
  {
    year: "2014",
    position: "مجتمع الأمن المعلوماتي",
    compnayName: "secommunity",
    details: `موقع عربي متخصص في مجال  الحماية والأمن المعلوماتي، تم تأسيسه سنة 2013 من طرف أشرف الدوس.
    يعتبر من أفضل المواقع العربية في إثراء محتوى الأمن المعلوماتي ويساعد على بناء مجتمع معلوماتي أكثر آمانًا وإدراكًا على صعيد العالم العربي.
    `,
  },
  {
    year: "2017",
    position: "الصحة العامة",
    compnayName: "Public Health",
    details: `أمتلك عدة قنوات عبر اليوتيوب في المجال الصحي. تعتبر الأولى على مستوى الشرق الأوسط في عرض المحتوي الطبي بنوعيه المرئي والكتابي.
    يتعاون عبر القنوات أكثر من 140 طبيب من مختلف دول العالم.
     `,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
