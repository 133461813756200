import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">العنوان</span>Palestine - Gaza Strip - Gaza City ,
        Al Ramal 00972.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">البريد الالكتروني</span>{" "}
        <a href="mailto:Google@ashrafeldous.com">Google@ashrafeldous.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">اتصل بي</span>{" "}
        <a href="Tel: +972 598 996527">+972 598 996527</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
