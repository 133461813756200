import React from "react";

const ModalFourBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> Ashraf Eldous
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 9 January 2022
        </span>
        <span>
          <i className="fa fa-tags"></i> الأمن المعلومات
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>مجتمع الأمن المعلوماتي</h1>
      <img src="img/blog/blog-post-4.jpg" className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>
        مجتمع الأمن المعلوماتي مجتمع تقني تعليمي مجاني يضم خيرة الشباب الأكفاء
 في مجال الحماية المعلوماتية كل حسب تخصصه. 
ستجد فيه دروسًا تطويريةً على شكل مقالات أو فيديوهات لمختلف المجالات المعلوماتية 
كالبرمجة وأنظمة التشغيل وعلوم الحاسب وأيضا التصميم..
        </p>
        <div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>
          يعمل مجتمع الأمن المعلوماتي على العديد من التوجهات منها إخبارية وتعليمية، حيث ينشر أخبار الهاكرز العرب والدوليين وحوادث الاختراقات، والإنجازات على صعيد الحروب الإلكترونية،
          </p>
        </div>
        <p>
        وكذا تتبع آخر الثغرات الأمنية وكيفية ترقيعها والحماية منها، أما الجانب التعليمي من المجتمع فيضم كم هائل من الجوانب منها دروس في الهاكينج الأخلاقي ونظام لينكس، والبرمجة بكافة لغاتها، 
        </p>
        <p>
        وكذا تطوير المواقع والعديد من التوجهات المتعلقة، إضافة إلى تطلعه إلى تنظيم ملتقيات و مؤتمرات على الصعيد الوطني بهدف تأثيث الفضاء الرقمي المغربي.
          
          </p>
        <p>
        جانب مهم في هذا المجتمع هو نشر الثقافة الأمنية  لكل من ليست لدية دراية بكيفية حماية بياناته على الشبكة العنكبوتية
        </p>
        <p>
        حيث نلاحظ في الآونة الأخيرة اختراق المستخدمين لبطاقاتهم البنكية وحساباتهم الشخصية والمهنية
        </p>
        <p>
        وهذا في حد ذاته خطر عليهم و ذلك لأنهم غافلين عن الجانب الأمني في العالم الافتراضي و يسعى الموقع من خلال دوراته سهلة البسيطة الفهم إلى حماية المستخدم و تنمية ثقافته الأمنية إضافة إلى نشر ثقافة القرصنة الأخلاقية.
        </p>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalFourBlogContent;
