import React from "react";

const educationContent = [
  {
    year: "2015",
    degree: "تهيئة محركات البحث",
    institute: "SEO",
    details: `  الظهور في النتائج الاولي في محرك البحث جوجل ادهش الجميع
      الدولار - الذهب - حافز - سعر - السعودية`,
  },
  {
    year: "2008",
    degree: "جوجل ادسنس",
    institute: "Google AdSense",
    details: `بدأت رحلتي المهنية عام 2008 وقمت بإنشاء أول موقع بسكربت خاص 
    وقمت باضافة اكواد جوجل ادسنس للحصول على أرباح وهنا بدأت حياتي المهنية`,
  },
  {
    year: "2017",
    degree: "يوتيوب",
    institute: "YouTube",
    details: `اما على مستوى إعداد المحتوى المرئي فأمتلك سلسلة من القنوات العربية والأجنبية
    تضم ملايين المشتركين بمجالات مختلفة مثل الطب وامن المعلومات وغيرها. 
   `,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
