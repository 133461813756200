import React from "react";

const skillsContent = [
  { skillClass: "p80", skillPercent: "80", skillName: "YouTube" },
  { skillClass: "p89", skillPercent: "89", skillName: "SEO" },
  { skillClass: "p95", skillPercent: "95", skillName: "Google AdSense" },
  { skillClass: "p76", skillPercent: "76", skillName: "Kali Linux" },
  { skillClass: "p95", skillPercent: "95", skillName: "WORDPRESS" },
  { skillClass: "p71", skillPercent: "71", skillName: "Java" },
  { skillClass: "p93", skillPercent: "93", skillName: "Digital Marketing" },
  { skillClass: "p98", skillPercent: "98", skillName: "Gaming" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
